import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import { useState } from "react"
import { useEffect } from "react"
import not_found from '../assets/illustrations/stylish/settings-1768784-0.svg'
import Layout from "../components/layout"
import Seo from "../components/seo"
import logo from '../images/favicon/tf_round_blue.png'

export default function PathRouter() {
  const [showOptions, setShowOptions] = useState(false);
  const qsafeLink = "https://portal.timefree.ph/qsafe/clientb.aspx?q=";

  useEffect(() => {
    let path = window.location.pathname.split("/");
    console.log('path', path, path[1]);
    setTimeout(() => {
      switch (path[1].toLowerCase()) {
        case "qsafe":
          if (path[2])
            window.location.href = `https://portal.timefree.ph/qsafe/clientb.aspx?q=${path[2]}`;
          else window.location.href = `https://portal.timefree.ph/qsafe/clientb.aspx?q=`;
          break;
        case "qsb":
          if (path[2])
            window.location.href = `https://portal.timefree.ph/qsafe/clientb.aspx?q=${path[2]}`;
          else window.location.href = `https://portal.timefree.ph/qsafe/clientb.aspx?q=`;
          break;
        case "dlshsi":
          window.location.href = `https://portal.timefree.ph/qsafe/clientb.aspx?q=NTUuMQ`;
          break;
        case "dtizc":
          window.location.href = `https://portal.timefree.ph/qsafe/clientb.aspx?q=NTAuMQ`;
          break;
        default:
          // window.location.href = "/404";
          setShowOptions(true);
          break;
      }
    }, 2000);
  });

  return (
    <Layout>
      {/* <> */}
      <Seo title="Path Finder" />
      <div className="flex flex-col flex-wrap content-center justify-center py-20 lg:pt-28 h-full w-screen text-center px-10">
        {showOptions &&
          <div>
            <div>
              {/* <StaticImage
                className="max-h-20 w-auto object-contain"
                src={logo}
                placeholder="tracedSVG"
                alt=""
              /> */}
              <h1 className="font-black text-gray-700 mt-1 text-3xl">We can't seem to find the page you were looking for.</h1>
              <p className="font-normal text-gray-600 mt-2 text-2xl">Where were you headed?</p>
              <div className="flex flex-col justify-center w-full mt-4">
                <div className="w-full">
                  <Link to="/getqmobile" className="w-full sm:w-1/2 rounded-md shadow  inline-flex items-center justify-center px-5 py-3 border border-transparent text-lg md:text-xl 4xl:text-3xl font-medium rounded-md text-gray-50 bg-darkblue-tf  hover:bg-blue-tf">
                    Download QMobile
                  </Link>
                </div>
                <div className="w-full mt-2">
                  <Link to={qsafeLink} className="w-full sm:w-1/2 rounded-md shadow inline-flex items-center justify-center px-5 py-3 border border-transparent text-lg md:text-xl 4xl:text-3xl font-medium rounded-md text-gray-50 bg-qsafe-darkgreen  hover:bg-accent-tf">
                    QSafe | Contact Tracing
                  </Link>
                </div>
                <div className="w-full mt-2">
                  <Link to="/#" className="font-normal text-darkblue-tf mt-4 text-lg  flex content-center flex-wrap justify-center gap-x-2">Go back home
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-darkblue-tf " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
          </div>}
        {!showOptions &&
          <div>
            {/* <StaticImage
              className="max-h-20 w-auto object-contain"
              src={logo}
              placeholder="tracedSVG"
              alt=""
            /> */}
            <h1 className="font-black text-gray-700 mt-1 text-4xl">We are looking the page you are trying to reach.</h1>
            <p className="font-normal text-gray-400 mt-2 text-lg">We will redirect you to your intended page as soon as we find it.</p>
            <div>
              <Link to="" className="font-normal text-darkblue-tf mt-4 text-lg  flex content-center flex-wrap justify-center gap-x-2">Click here if you are not redirectly in 10 seconds
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-darkblue-tf " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                </svg>
              </Link>
            </div>
          </div>
        }

      </div>
      {/* </> */}
    </Layout>
  )
}